<template>
  <b-form @submit.prevent="submit">
    <b-form-group label-for="input-code" label="Inserisci codice inviato alla email inserita">
      <b-form-input :state="validateState('token')" id="input-code" v-model="token" placeholder="Codice" aria-describedby="input-token-feedback" />
      <b-form-invalid-feedback id="input-code-feedback">Compila questo campo.</b-form-invalid-feedback>
    </b-form-group>
    <b-button block type="submit" variant="primary">Verifica</b-button>
  </b-form>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  name: "VerifyCodeForm",
  data() {
    return {
      token: "",
    };
  },
  validations: {
    token: {
      required,
    },
  },
  mixins: [validationMixin],
  methods: {
    submit() {
      this.$v.$touch();

      if (!this.$v.$invalid) this.$emit("submit", this.token);
    },

    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
  },
};
</script>

<style></style>
