<template>
  <div>
    <b-alert :show="error != ''" variant="danger">{{ error }}</b-alert>
    <b-form ref="dataForm" v-if="firstStep" @submit.prevent="submit">
      <b-form-group>
        <b-form-input :state="validateState('name')" v-model="input.name" placeholder="Nome e cognome"
          autocomplete="name" aria-describedby="input-name-feedback" />
        <b-form-invalid-feedback id="input-code-feedback">Compila questo campo.</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group>
        <b-form-input :state="validateState('email')" v-model="input.email" placeholder="Email" autocomplete="email"
          aria-describedby="input-email-feedback" />
        <b-form-invalid-feedback id="input-code-feedback">{{ input.email ? "Email non valida." : "Compila questo campo."
        }}</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group>
        <b-form-input :state="validateState('phone')" v-model="input.phone" placeholder="Telefono" autocomplete="tel"
          aria-describedby="input-phone-feedback" />
        <b-form-invalid-feedback id="input-code-feedback">Compila questo campo.</b-form-invalid-feedback>
      </b-form-group>
      <b-button :disabled="loading" block type="submit" variant="primary">
        Prenota
        <b-spinner v-if="loading" small type="grow"></b-spinner>
      </b-button>
    </b-form>
    <verify-code-form v-else @submit="verifyReservation" />
  </div>
</template>

<script>
import { graphQLErrorMessage } from "@/graphql/utils";
import VerifyCodeForm from "./VerifyCodeForm.vue";

import { required, email } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  components: { VerifyCodeForm },
  name: "NewReservationForm",
  props: {
    reservationInfo: {
      type: Object,
      required: true,
    },
  },
  validations: {
    input: {
      name: {
        required,
      },
      phone: {
        required,
      },
      email: {
        required,
        email,
      },
    },
  },
  mixins: [validationMixin],

  data() {
    return {
      input: {
        phone: "",
        name: "",
        email: "",
      },

      firstStep: true,
      loading: false,
      error: "",

      currentMeeting: null,
    };
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.input[name];
      return $dirty ? !$error : null;
    },

    submit() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      this.loading = true;
      this.$apollo
        .mutate({
          mutation: require("../graphql/CreateMeeting.gql"),
          variables: {
            input: {
              ...this.input,
              ...this.reservationInfo,
            },
          },
        })
        .then(({ data }) => {
          this.currentMeeting = data.createMeeting;
          this.$refs.dataForm.reset();
          this.firstStep = false;
          this.loading = false;
          this.error = "";
        })
        .catch((error) => {
          this.loading = false;
          this.error = graphQLErrorMessage(error) ?? "Qualcosa è andato storto, prenotazione non effettuata";
        });
    },

    verifyReservation(token) {
      this.$apollo
        .mutate({
          mutation: require("../graphql/ConfirmMeeting.gql"),
          variables: { id: this.currentMeeting.id, token },
          update: (store, { data: { confirmMeeting } }) => {
            if (confirmMeeting) {
              let data = store.readQuery({
                query: require("../graphql/Meetings.gql"),
                variables: { office_id: this.reservationInfo.office_id, confirmed: true },
              });
              data.meetings.push(this.currentMeeting);
              store.writeQuery({
                query: require("../graphql/Meetings.gql"),
                variables: { office_id: this.reservationInfo.office_id, confirmed: true },
                data,
              });
            }
          },
        })
        .then(({ data: { confirmMeeting } }) => {
          if (confirmMeeting) this.$emit("created", this.currentMeeting);
          else {
            this.loading = false;
            this.error = "Codice non corretto, riprova";
            this.token = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = graphQLErrorMessage(error).message ?? "Qualcosa è andato storto, prenotazione non effettuata";
          this.loading = false;
        });
    },
  },
};
</script>

<style>

</style>
