<template>
  <div>
    <b-alert :show="error != ''" variant="danger">{{ error }}</b-alert>
    <b-container v-if="authorized">
      <dl class="row">
        <dt class="col-sm-3">Nome:</dt>
        <dd class="col-sm-9">{{ meeting.name }}</dd>

        <dt class="col-sm-3">Email:</dt>
        <dd class="col-sm-9">{{ meeting.email }}</dd>

        <dt class="col-sm-3">Telefono:</dt>
        <dd class="col-sm-9">{{ meeting.phone }}</dd>
      </dl>
      <b-btn block variant="danger" @click="deleteEvent">Elimina</b-btn>
    </b-container>
    <verify-code-form v-else @submit="authorize" />
  </div>
</template>

<script>
import { graphQLErrorMessage } from "@/graphql/utils";
import VerifyCodeForm from "./VerifyCodeForm.vue";

export default {
  components: { VerifyCodeForm },
  props: {
    event_id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      authorized: false,
      loading: false,
      meeting: null,
      error: "",
      token: "",
    };
  },

  methods: {
    authorize(token) {
      this.token = token;
      this.$apollo
        .query({
          query: require("../graphql/Meeting.gql"),
          variables: { id: this.event_id, token },
        })
        .then(({ data }) => {
          this.loading = false;
          if (data.meeting) {
            this.authorized = true;
            this.meeting = data.meeting;
            this.error = "";
          } else {
            this.error = "Non puoi vedere questa prenotazione";
            this.authorized = false;
          }
        })
        .catch((error) => (this.error = graphQLErrorMessage(error)));
    },

    deleteEvent() {
      if (!this.token) {
        this.authorized = false;
        return;
      }

      let response = confirm(`Eliminare la prenotazione di '${this.meeting.name}'?`);
      if (response) {
        this.$apollo
          .mutate({
            mutation: require("../graphql/DeleteMeeting.gql"),
            variables: { id: this.meeting.id, token: this.token },
            update: (store, { data: { deleteMeeting } }) => {
              if (!deleteMeeting) {
                this.error = "Codice non valido";
                return;
              }

              let data = store.readQuery({
                query: require("../graphql/Meetings.gql"),
                variables: { office_id: deleteMeeting.office_id, confirmed: true },
              });
              data.meetings = data.meetings.filter((meeting) => meeting.id != deleteMeeting.id);
              store.writeQuery({
                query: require("../graphql/Meetings.gql"),
                variables: { office_id: deleteMeeting.office_id, confirmed: true },
                data,
              });
              this.$emit("deleted", deleteMeeting);
            },
          })
          .catch((error) => {
            console.log(error);
            this.error = graphQLErrorMessage(error) || "Qualcosa è andato storto, prenotazione non cancellata";
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style></style>
