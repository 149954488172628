import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import "./plugins/vuelidate";
import App from "./App.vue";
import { createProvider } from "./vue-apollo";
import moment from "moment";

Vue.config.productionTip = false;
moment.locale("it");

new Vue({
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount("#app");
