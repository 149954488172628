<template>
  <div id="app">
    <b-navbar type="dark" variant="primary">
      <b-navbar-brand href="https://filarmonicamaniago.it">Prenotazioni segreteria AFM</b-navbar-brand>
    </b-navbar>

    <b-jumbotron>
      <h3>Istruzioni</h3>
      <p>
        Seleziona uno slot libero in uno dei {{ offices.length }} uffici e inserisci i dati richiesti. Riceverai un codice alla mail inserita che ti
        sarà richiesto per la conferma della prenotazione. Se vengono riscontrati problemi da pc è possibile prenotare anche tramite smartphone
        tenendo premuto sullo slot e seguendo la stessa procedura.
      </p>
      <p>
        <strong>Nell'attesa del codice non chiudere la finestra o il form di inserimento.</strong>
      </p>
      <p>Per visualizzare o cancellare la propria prenotazione è sufficiente cliccare sul relativo slot e inserire il proprio codice personale.</p>
      <p>
        <strong>È consentita al massimo una prenotazione a persona.</strong>
      </p>
    </b-jumbotron>
    <b-container>
      <MeetingCalendar v-for="office_id in offices" :key="office_id" class="mb-4" :office_id="office_id" />
      <div v-if="offices.length == 0">
        <h4>Le prenotazioni in segreteria sono momentaneamente chiuse</h4>
      </div>
    </b-container>

    <b-navbar class="mt-4" variant="dark" type="dark">
      <b-navbar-brand>
        <small>Developed by Bytenest di Roberto Del Ben</small>
      </b-navbar-brand>
    </b-navbar>
  </div>
</template>

<script>
import MeetingCalendar from "./components/MeetingCalendar.vue";

export default {
  name: "App",
  components: {
    MeetingCalendar,
  },
  data() {
    return {
      offices: [...Array(parseInt(process.env.VUE_APP_OFFICE_NUMBER)).keys()],
    };
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

body {
  margin: 0;
}

#app {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
